import React, {
  useCallback,
  useRef,
  useEffect,
  useState,
  useMemo,
} from "react";
import ExtendedContentWysiwyg from "./Wysiwyg";
import Link from "theme/components/atoms/Typography/Link";

/**
 * Gets the screen mode
 * 	2 - Desktop
 *  3 - Mobile
 *  4 - Tablet
 */
function checkScreenMode() {
  const width = typeof window !== "undefined" ? window.innerWidth : null;
  if (width >= 999) {
    // Desktop
    return 2;
  } else if (width < 599) {
    // Mobile
    return 3;
  } else if (width < 999 && width >= 599) {
    // Tablet
    return 4;
  }
}

const ExtendedContent = ({ attributes, data }) => {
  const arrColsLength = 4;
  const extendedcontentContainerRef = useRef();
  const extendedcontentRef = useRef();
  const extendedcontent = data.extendedcontent;
  const enableResize = attributes.enable_resize;
  const display_mode = attributes?.display_mode
    ? parseInt(attributes.display_mode)
    : "";
  const isVisibleOnAllDevices = display_mode === 1 || display_mode === "";
  const [isVisible, setIsVisible] = useState(isVisibleOnAllDevices);
  const [colsRefs, setColsRefs] = useState([]);

  useEffect(() => {
    setColsRefs((elRefs) =>
      Array(arrColsLength)
        .fill()
        .map((_, i) => elRefs[i] || React.createRef())
    );
  }, [arrColsLength]);

  const getContentFinalHeight = useCallback(
    (element) => {
      var style = window.getComputedStyle
        ? getComputedStyle(element, null)
        : element.currentStyle;
      const width = typeof window !== "undefined" ? window.innerWidth : 1;
      let fullHeight = attributes.full_height ? attributes.full_height : 520;
      const fullWidth = 1500;

      const getPropertyValue = (property) =>
        parseFloat(style.getPropertyValue(property));

      const paddingTop = getPropertyValue("padding-top");
      const paddingBottom = getPropertyValue("padding-bottom");
      const borderBottomWidth = getPropertyValue("border-bottom-width");
      const borderTopWidth = getPropertyValue("border-top-width");
      fullHeight =
        fullHeight -
        (paddingTop - paddingBottom - borderBottomWidth - borderTopWidth);

      if (width >= fullWidth) {
        return fullHeight;
      } else {
        return (fullHeight * width) / fullWidth;
      }
    },
    [attributes.full_height]
  );

  const setContentHeights = useCallback(
    (element) => {
      if (!element || typeof window === "undefined") {
        return;
      }
      const height = getContentFinalHeight(extendedcontentContainerRef.current);
      extendedcontentContainerRef.current.style.height = `${Math.ceil(
        height
      )}px`;
      extendedcontentContainerRef.current.style.minHeight = `${Math.ceil(
        height
      )}px`;

      [extendedcontentRef].forEach((ref) => {
        if (ref?.current) {
          ref.current.style.height = `${Math.ceil(height)}px`;
        }
      });
    },
    [getContentFinalHeight, extendedcontentContainerRef]
  );

  const handleResize = useCallback(() => {
    if (!isVisibleOnAllDevices) {
      const screenMode = checkScreenMode();
      if (
        screenMode === display_mode ||
        ((screenMode === 3 || screenMode === 4) && display_mode === 5)
      ) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    }

    if (enableResize && extendedcontentContainerRef.current) {
      setContentHeights(extendedcontentContainerRef.current);
    }
  }, [display_mode, isVisibleOnAllDevices, enableResize, setContentHeights]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [enableResize, handleResize]);

  const isValideToDay = useMemo(() => {
    if (!extendedcontent) {
      return null;
    }
    const dateNow = Date.now();
    let activeTo = extendedcontent.activeTo;
    let activeFrom = extendedcontent.activeFrom;
    activeTo = activeTo ? Date.parse(activeTo) : null;
    activeFrom = activeFrom ? Date.parse(activeFrom) : null;
    if (extendedcontent.status) {
      if (activeFrom) {
        if (activeFrom > dateNow) {
          return false;
        }
      }
      if (activeTo) {
        if (activeTo < dateNow) {
          return false;
        }
      }
      return true;
    }
    return false;
  }, [extendedcontent]);

  const renderExtendedContent = useMemo(() => {
    if (!extendedcontent) {
      return null;
    }

    return (
      <div
        className={extendedcontent.className}
        id={`widget-id-${extendedcontent.entity_id}`}
        style={extendedcontent.css}
        ref={extendedcontentContainerRef}
      >
        <div
          className={extendedcontent.directChildClassName}
          ref={extendedcontentRef}
        >
          {extendedcontent.cols.map((col, index) => {
            if (!col.isEnabled) {
              return null;
            }
            return (
              <div
                key={index}
                className={col.className}
                style={col.css}
                ref={colsRefs[index]}
              >
                <ExtendedContentWysiwyg content={col.content} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }, [extendedcontent, extendedcontentContainerRef, colsRefs]);

  if (!extendedcontent || !isValideToDay || !isVisible) {
    return null;
  }

  return (
    <div id={`outer-extendedcontent-${extendedcontent.entity_id}`}>
      {extendedcontent.globalLink ? (
        <Link
          to={extendedcontent.globalLink}
          className="extendedcontent-content-global-link"
          stripBaseUrl
        >
          {renderExtendedContent}
        </Link>
      ) : (
        renderExtendedContent
      )}
    </div>
  );
};

export default ExtendedContent;
